import React from "react"
import Layout from "../components/layout/Layout"
import Kontaktai from "../components/general/contacts/Kontaktai"
import { Helmet } from "react-helmet"
import styles from "../styles/text-section.module.scss"

export default function ApieMus() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Reikalingas automobilio remontas? Mes galime jums padėti.</title>
        <meta
          name="description"
          content="Reikalingas automobilio remontas? Registruokitės apžiūrai. Kokybiškai ir greitai atliksime variklio remontą, variklio diagnostiką, važiuoklės remontą, padangų keitimą, padangų remontą, ratų montavimą, ratų balansavimą, tepalų keitimą."
        ></meta>
      </Helmet>
      <div className={`${styles.textSection}`}>
        <h1>Registruokitės automobilio apžiūrai</h1>
        <Kontaktai showTitle={false} />
      </div>
    </Layout>
  )
}
